/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Meta {
  name: string;
  content: string;
}

const SEO = ({ description, lang = 'sv', title, image, slug, type }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (image !== null) {
    meta.push({
      property: `og:image`,
      content: image,
    });
  }

  return (
    <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s | Osynlig`} meta={meta}>
      <link rel="canonical" href={canonicalPath(type, slug)} />
    </Helmet>
  );
};

const canonicalPath = (typename: string, path: string): string => {
  const paths = {
    CMS_Page: '',
    CMS_Article: 'artiklar/',
    CMS_Case: 'case/',
  };

  path = `${path == 'startsida' ? '' : path + '/'}`;

  return `https://osynlig.se/${paths[typename]}${path}`;
};

export default SEO;
